$(window).scroll(() => {
    PageUpAnime();
});

$(window).on('load', () => {
    PageUpAnime();
});

$('.cmn-page-up').click(() => {
    $('body,html').animate({
        scrollTop: 0,
    }, 500);
    return false;
});

function PageUpAnime() {
    const scroll = $(window).scrollTop();
    let threshold;
    if ($(window).width() > 991) {
        threshold = 600;
    } else {
        threshold = 400;
    }
    if (scroll >= threshold) {
        $('.cmn-page-up').removeClass('jsDisappear');
        $('.cmn-page-up').addClass('jsAppear');
    } else if ($('.cmn-page-up').hasClass('jsAppear')) {
        $('.cmn-page-up').removeClass('jsAppear');
        $('.cmn-page-up').addClass('jsDisappear');
    }
}
